/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, useLocation } from 'react-router-dom';
import ProfileContext from 'components/profileContext';
import { getProfile } from 'utils/api';
import { useEffect, useState } from 'react';
import Surveys from 'pages/surveys';
import SurveysDetails from 'pages/survey-details';
import { isLocalhost, isSuperAdmin } from 'utils/utils';
import posthog from 'posthog-js';

const NotFound = () => (
  <div>
    <h2 style={{ textAlign: 'center', marginTop: '24px' }}>Page not found</h2>
  </div>
);
const AppWrapper = () => {
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const location = useLocation();
  const updateProfile = () => {
    if (location.pathname.substring(0, 5) !== '/auth') {
      return getProfile()
        .then((data) => {
          if (!isLocalhost() && !isSuperAdmin(data)) {
            if (!firstLoadDone) {
              posthog.init('phc_KWaGD3InupIUXo2igcXIHj407U5chVHneP8RSs8CVZ0', {
                api_host: 'https://eu.posthog.com',
              });
            }
            posthog.identify(data.userId, {
              name: data.name,
              workspaceId: data.workspaceId,
              workspaceName: data.workspaceName,
              isAdmin: data.isAdmin,
            });
            posthog.group('workspace', data.workspaceId, {
              name: data.workspaceName,
            });
          }
          return setProfileInfo({
            profile: data || {},
            updateProfile,
          });
        })
        .catch((e) => {
          console.error('error in app', e);
        });
    }
  };

  const [profileInfos, setProfileInfo] = useState({
    profile: {},
    updateProfile: updateProfile,
  });

  useEffect(() => {
    updateProfile().then(() => {
      setFirstLoadDone(true);
    });
  }, []);

  const sess = localStorage.getItem('sessionId_pulsy');
  if (!sess || sess.length === 0) {
    window.location.href = '/#/auth';
    window.location.reload();
  }

  return (
    <>
      <ProfileContext.Provider value={profileInfos}>
        {firstLoadDone && (
          <Routes>
            <Route path="/" element={<Surveys />} />
            <Route path="/surveys/:id" element={<SurveysDetails />} />
            <Route path="*" exact={true} element={<NotFound />} />
          </Routes>
        )}
      </ProfileContext.Provider>
    </>
  );
};

export default AppWrapper;
