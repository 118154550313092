import axios from 'axios';
import { getApiURL } from './utils';

const instance = axios.create({
  // baseURL: 'http://localhost:9781',
  baseURL: getApiURL(),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const auth = (token) =>
  instance
    .post(
      `/web-app/auth`,
      {},
      {
        headers: {
          sessionId: token || localStorage.getItem('sessionId_pulsy'),
        },
      }
    )
    .then(() => {
      if (token) {
        localStorage.setItem('sessionId_pulsy', token);
      }
    });

export const getProfile = () =>
  instance
    .post(
      `/web-app/get-profile`,
      {},
      {
        headers: {
          sessionId: localStorage.getItem('sessionId_pulsy'),
        },
      }
    )
    .then(({ data }) => data);

export const listSurveys = () =>
  instance
    .post(
      `/web-app/surveys/list`,
      {},
      {
        headers: {
          sessionId: localStorage.getItem('sessionId_pulsy'),
        },
      }
    )
    .then(({ data }) => data);

export const getSurveyDetails = (surveyId, includeIncomplete = false) =>
  instance
    .post(
      `/web-app/surveys/get-by-id`,
      { surveyId, ...(includeIncomplete ? { includeIncomplete: true } : {}) },
      {
        headers: {
          sessionId: localStorage.getItem('sessionId_pulsy'),
        },
      }
    )
    .then(({ data }) => data);

export const getDataPoints = (questionId, type) =>
  instance
    .post(
      `/web-app/surveys/get-data-points`,
      { questionId, type },
      {
        headers: {
          sessionId: localStorage.getItem('sessionId_pulsy'),
        },
      }
    )
    .then(({ data }) => data);
export const getUserProfile = (params) =>
  instance
    .post(`/web-app/analytics/get-user-profile`, params, {
      headers: {
        sessionId: localStorage.getItem('sessionId_pulsy'),
      },
    })
    .then(({ data }) => data);

//“responseId”: “xxxxx“, // now provided in each answer
//“questionId”: “xxxxx”,
// “text”: “Can you clarify, bitch?”
export const askCkarifications = (params) =>
  instance
    .post(`/web-app/surveys/ask-clarifications`, params, {
      headers: {
        sessionId: localStorage.getItem('sessionId_pulsy'),
      },
    })
    .then(({ data }) => data);

export const download = (params) =>
  instance
    .post(`/web-app/surveys/export`, params, {
      headers: {
        sessionId: localStorage.getItem('sessionId_pulsy'),
      },
    })
    .then(({ data }) => data);
